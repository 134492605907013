<div class="">
  <div class="navigation-wrapper">
    <div class="keen-slider keen-slider--vertical" #sliderRef>
      <ng-content></ng-content>
    </div>

    <button
      class="carousel-arrow-button carousel-arrow-button--left"
      id="carousel-left-button"
      (click)="slider.prev()"
      (keydown)="onKeydown($event, 'left')"
      role="button"
      type="button"
      tabindex="0"
      aria-label="Previous item in carousel">
      <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
          fill="var(--color-content-primary)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M83 57.8642L82.9896 62.7027L49.2486 62.7772L49.2442 64.8005L59.3696 75.0612L55.986 78.49L41.3805 63.6894L41.3689 63.701L38 60.2871L56.046 42L59.4149 45.4139L49.2638 55.7007L49.2589 57.9386L83 57.8642Z" />
      </svg>
    </button>

    <button
      class="carousel-arrow-button carousel-arrow-button--right"
      id="carousel-right-button"
      (click)="slider.next()"
      (keydown)="onKeydown($event, 'right')"
      role="button"
      type="button"
      tabindex="0"
      aria-label="Next item in carousel">
      <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
          fill="var(--color-content-primary)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38 62.6239L38.0104 57.7857L71.7515 57.7113L71.7558 55.6882L61.6304 45.4285L65.014 42L79.6195 56.7992L79.6311 56.7875L83 60.2011L64.954 78.4865L61.5851 75.0729L71.7363 64.7871L71.7411 62.5494L38 62.6239Z" />
      </svg>
    </button>
  </div>

  <span class="dots">
    <div class="dash-container" role="tablist">
      <button
        id="dots-button-{{ i }}"
        (click)="slider.moveToIdx(i)"
        *ngFor="let slide of dotHelper; let i = index"
        [class]="'dot ' + (i === currentSlide ? 'active' : '')"
        role="tab"
        type="button"
        tabindex="0"
        [attr.aria-label]="'Go to carousel item ' + (i + 1)"
        [attr.aria-controls]="'tabpanel-' + i"
        [attr.aria-selected]="i === currentSlide ? 'true' : 'false'"></button>
    </div>
  </span>
</div>
