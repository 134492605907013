export const addCanonicalTag = (canonical: string, document: Document): void => {
  const hostName =
    window.location.hostname === 'www.poplin.co' || window.location.hostname === 'poplin.co'
      ? 'https://poplin.co/'
      : 'https://nonprod.poplin.co/';
  const url = hostName + canonical;

  let link: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`);

  if (link) {
    link.href = url;
  } else {
    link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    document.head.appendChild(link);
  }
};
