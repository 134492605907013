<div class="featured-on">
  <h2>Featured On</h2>
  <ul>
    <li>
      <img src="assets/images/logo-nbc.webp" alt="NBC Logo" aria-hidden="true" />
      <span class="sr-only">NBC</span>
    </li>
    <li>
      <img src="assets/images/logo-fox-friends.webp" alt="Fox & Friends Logo" aria-hidden="true" />
      <span class="sr-only">Fox &amp; Friends</span>
    </li>
    <li>
      <img src="assets/images/logo-abc.webp" alt="ABC Logo" aria-hidden="true" />
      <span class="sr-only">ABC</span>
    </li>
    <li>
      <img
        class="affairs-logo"
        src="assets/images/logo-consumer-affairs.webp"
        alt="Consumer Affairs Logo"
        aria-hidden="true" />
      <span class="sr-only">Consumer Affairs</span>
    </li>
    <li>
      <img src="assets/images/logo-cbs.webp" alt="CBS Logo" aria-hidden="true" />
      <span class="sr-only">CBS</span>
    </li>
    <li>
      <img src="assets/images/logo-fox.webp" alt="Fox Business Logo" aria-hidden="true" />
      <span class="sr-only">Fox Business</span>
    </li>
  </ul>
</div>
