import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyImgDirective } from 'src/app/directives/image.directive';
import { PlatformId } from 'src/app/types/common';
import { environment } from '../../../../environments/environment';
import { BusinessComponent } from '../../../pages/business/business.component';
import { openCustomerAppWithQueryParams } from '../../../util';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, LazyImgDirective],
  selector: 'app-promo-content',
  templateUrl: './promo-content.component.html',
  styleUrls: ['./promo-content.component.scss'],
})
export class PromoContentComponent implements OnInit, AfterViewInit {
  constructor(private businessComponent: BusinessComponent, @Inject(PLATFORM_ID) private platformId: PlatformId) {}
  isBrowser = isPlatformBrowser(this.platformId);

  @Input() content: Array<string> = [];
  @Input() classes = '';
  @Input() bkgImg = '';
  @Input() hero = false;
  @Input() showButton = false;
  @Input() backgroundGradient = '';
  @Input() showGetStarted = false;
  @Input() business: boolean;
  launderer = false;
  affiliates = false;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.launderer = window.location.pathname === '/work-from-home';
      this.affiliates = window.location.pathname === '/affiliates';
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.setVh();
    }
  }

  setStyles() {
    if (this.backgroundGradient) {
      return {
        'background-image': `url(assets/images/${this.bkgImg}), ${this.backgroundGradient}`,
      };
    } else {
      return {
        'background-image': `url(assets/images/${this.bkgImg})`,
      };
    }
  }

  signUp(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    openCustomerAppWithQueryParams('signup', target.id);
  }

  signUpAffiliates() {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = 'http://app.impact.com/advertiser-advertiser-info/Poplin.brand';
    }
  }

  proDownloadApp() {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = environment.dynamicLinks.laundryPro;
    }
  }

  setVh() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  async getStartedBusiness() {
    await this.businessComponent.getStarted();
  }
}
