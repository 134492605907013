<div id="preferences" class="preferences">
  <div *ngIf="!business" class="home">
    <h2>Preferences</h2>
    <h3>Particular about <br />your laundry? Us too.</h3>
    <ul id="dynamicTexts" class="dynamic-texts" aria-live="polite">
      <li id="one" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Delicate Wash?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="two" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Done!</p>
      </li>
      <li id="three" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Hang dry?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="four" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Happy to!</p>
      </li>
      <li id="five" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Hypoallergenic?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="six" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Sure!</p>
      </li>
    </ul>
    <ion-button id="preferences-request-pickup-button" class="large contained" (click)="signUp($event)">
      Create Account
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
  </div>
  <div *ngIf="business" class="business">
    <h2>HOSPITALITY INSPIRED</h2>
    <h3>Need top notch service?<br />We're your laundry concierge.</h3>
    <ul id="dynamicTexts" class="dynamic-texts" aria-live="polite">
      <li id="one" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Account manager?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="two" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Yes!</p>
      </li>
      <li id="three" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Multiple locations?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="four" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Absolutely!</p>
      </li>
      <li id="five" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Got an app?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="six" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>We do!</p>
      </li>
      <li id="seven" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Track progress?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="eight" tabindex="0">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender" aria-hidden="true"
        /></span>
        <p>Yup!</p>
      </li>
    </ul>
    <ion-button
      id="preferences-get-started-business-button"
      *ngIf="business"
      class="large continue"
      (click)="getStartedBusiness()">
      Get Started
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
  </div>
</div>
