<div class="profile-wrapper" [class.work-from-home-wrapper]="workFromHome" [class.testimonials-wrapper]="testimonials">
  <div
    class="profile"
    [class.work-from-home-badge]="workFromHome"
    [ngClass]="{ 'pro-badge': showProBadge, testimonials: testimonials }"
    *ngIf="!imageCover">
    <div class="top" [class.customer-testimonials-top]="customerTestimonials && mobile">
      <div class="city" [hidden]="mobile && customerTestimonials">
        <span>{{ city.toUpperCase() }}</span>
      </div>
      <div class="profile-pic">
        <img [src]="picture" alt="profile picture" aria-hidden="true" />
      </div>
      <div class="rating">
        <ion-icon aria-label="10 out of 10 ratings" src="assets/images/testimonial-rating.svg"></ion-icon>
      </div>
    </div>
    <p *ngIf="showName" class="name">{{ name }}</p>
    <div class="chip-text">Laundry Pro since {{ date }}</div>
    <div class="quote" [ngClass]="{ 'pro-quote': showQuote && showProBadge }" *ngIf="showQuote">
      <p>{{ quote }}</p>
      <p *ngIf="quoteAuthor" class="quote-author">&mdash; {{ quoteAuthor }}</p>
    </div>
  </div>
  <div class="image-cover" *ngIf="imageCover">
    <img [src]="picture" alt="Benefits picture" />
  </div>
</div>
