import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, IonInput } from '@ionic/angular';
import { PlatformId } from 'src/app/types/common';
import { environment } from '../../../../environments/environment';
import { BusinessComponent } from '../../../pages/business/business.component';
import { ApiService } from '../../../services';
import { openCustomerAppWithQueryParams } from '../../../util';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  selector: 'app-check-your-area',
  templateUrl: './check-your-area.component.html',
  styleUrls: ['./check-your-area.component.scss'],
})
export class CheckYourAreaComponent {
  @ViewChild('loadingText') loadingText: ElementRef;
  @ViewChild('zipCodeInput', { read: IonInput }) zipCodeInput!: IonInput;

  @Input() faq: boolean;
  @Input() business: boolean;
  @Input() bottomHome: boolean;
  @Input() hideInput: boolean;

  zipCodeFormGroup: FormGroup;
  zipCode = '';
  success = false;
  failure = false;
  invalidZipForm = false;
  loading = false;
  resetting = false;
  hideCursor = false;
  emptyZipCode = true;
  serverFailure = false;
  buttonFocused = false;

  constructor(
    private apiService: ApiService,
    private businessComponent: BusinessComponent,
    @Inject(PLATFORM_ID) private platformId: PlatformId
  ) {
    this.zipCodeFormGroup = new FormGroup({
      ZipCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{5}$')]),
    });
    this.hideInput = false;
  }

  checkZipCode() {
    if (this.zipCodeFormGroup.invalid) {
      this.invalidZipForm = true;
    } else {
      this.invalidZipForm = false;
    }
    if (!this.zipCodeFormGroup.value.ZipCode || this.zipCodeFormGroup.value.ZipCode.length != 5) {
      return;
    }
    this.zipCode = this.zipCodeFormGroup.value.ZipCode;
    this.loading = true;
    this.setLoading();
    return this.apiService.get(`/general/v1/check-coverage?zipCode=${this.zipCode}`).subscribe(
      res => {
        if (res.statusCode === 200) {
          this.success = true;
        }
        this.loading = false;

        setTimeout(() => {
          const createAccountButton = document.getElementById('zip-code-log-in-button');
          const createAccountButton2 = document.getElementById('zip-code-log-in-button-2');
          const beALpButton = document.getElementById('zip-code-lp-button');
          const getStartedButton = document.getElementById('pro-wash-get-started-business-button');

          if (!this.faq && !this.business) {
            createAccountButton?.focus();
          }

          if (this.bottomHome) {
            createAccountButton2?.focus();
          }

          if (this.faq && !this.business) {
            beALpButton?.focus();
          }

          if (!this.faq && this.business) {
            getStartedButton?.focus();
          }
        }, 10);
      },
      error => {
        const extractStatusCode = (error: Error) => {
          const match = error.message.match(/Error Code: (\d+)/);
          return match ? parseInt(match[1]) : null;
        };
        const statusCode = extractStatusCode(error);
        if (statusCode === 404) {
          this.failure = true;

          setTimeout(() => {
            const checkAnotherZipcode = document.getElementById('zip-code-failure-reset-button');
            const checkAnotherZipcode2 = document.getElementById('zip-code-failure-reset-button-2');

            if (!this.bottomHome) {
              checkAnotherZipcode?.focus();
            }

            if (this.bottomHome) {
              checkAnotherZipcode2?.focus();
            }
          }, 10);
        } else {
          this.serverFailure = true;
        }
        this.loading = false;
      }
    );
  }

  setLoading() {
    if (isPlatformBrowser(this.platformId)) {
      let count = 1;
      const loadingInterval = setInterval(() => {
        if (!this.loading) {
          clearInterval(loadingInterval);
          return;
        }
        switch (count) {
          case 0:
            this.loadingText.nativeElement.textContent = 'Checking.';
            break;
          case 1:
            this.loadingText.nativeElement.textContent = 'Checking..';
            break;
          case 2:
            this.loadingText.nativeElement.textContent = 'Checking...';
            break;
        }
        count = count === 2 ? 0 : count + 1;
      }, 1000);
    }
  }

  handleChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const allowedRegex = /^[0-9]*$/;

    if (this.failure) {
      this.resetCheck();
    }

    if (!allowedRegex.test(inputElement.value)) {
      inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
    }

    if (inputElement.value.length > 5) {
      inputElement.value = inputElement.value.slice(0, 5);
    }

    if (inputElement.value.length === 5) {
      this.emptyZipCode = false;
    } else {
      this.emptyZipCode = true;
    }

    if (inputElement.value.length >= 1) {
      this.hideCursor = true;
    } else {
      this.hideCursor = false;
    }

    this.zipCodeFormGroup.controls['ZipCode'].setValue(inputElement.value);
  }

  resetCheck() {
    this.success = false;
    this.failure = false;
    this.invalidZipForm = false;
    this.serverFailure = false;
    this.zipCode = '';
    this.zipCodeFormGroup.reset();
    this.zipCodeFormGroup.setValue({ ZipCode: '' });
    this.resetting = true;
    this.zipCodeInput.setFocus();
  }

  signUp(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    openCustomerAppWithQueryParams('signup', target.id);
  }

  signUpPro() {
    window.location.href = environment.dynamicLinks.laundryPro;
  }

  inputFocused: boolean;

  onFocus() {
    this.inputFocused = true;
  }

  onBlur() {
    this.inputFocused = false;
  }

  async getStartedBusiness() {
    await this.businessComponent.getStarted();
  }
}
