import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-skip-to-content',
  templateUrl: './skip-to-content.component.html',
  styleUrls: ['./skip-to-content.component.scss'],
})
export class SkipToContentComponent {
  constructor(private router: Router) {}

  getSkipLink(): string {
    return this.router.url + '#main';
  }
}
