import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IonicModule, ModalController } from '@ionic/angular';
import { PlatformId } from 'src/app/types/common';
import { addCanonicalTag } from 'src/app/util/canonical-tag';
import { BusinessSignupComponent } from '../../components/business/business-signup/business-signup.component';
import { ConfirmComponent } from '../../components/business/confirm/confirm.component';
import { ContactSalesComponent } from '../../components/business/contact-sales/contact-sales.component';
import { FeaturedOnComponent } from '../../components/common/featured-on/featured-on.component';
import { NestedAccordionComponent } from '../../components/common/nested-accordion/nested-accordion.component';
import { ProWashComponent } from '../../components/common/pro-wash/pro-wash.component';
import { PromoContentComponent } from '../../components/common/promo-content/promo-content.component';
import { TestimonialsComponent } from '../../components/common/testimonials/testimonials.component';
import { BannerComponent } from '../../components/home/banner/banner.component';
import { CheckYourAreaComponent } from '../../components/home/check-your-area/check-your-area.component';
import { ClothesProtectionComponent } from '../../components/home/clothes-protection/clothes-protection.component';
import { LoveLanguageComponent } from '../../components/home/love-language/love-language.component';
import { PreferencesComponent } from '../../components/home/preferences/preferences.component';
import { faqHeader, faqs } from '../../data/faqs';
import { FAQ } from '../../interfaces/common';
import { openCustomerAppWithQueryParams } from '../../util';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    NestedAccordionComponent,
    PromoContentComponent,
    FeaturedOnComponent,
    TestimonialsComponent,
    ClothesProtectionComponent,
    PreferencesComponent,
    LoveLanguageComponent,
    ProWashComponent,
    CheckYourAreaComponent,
    BannerComponent,
  ],
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class BusinessComponent implements OnInit {
  public heroImg: string;
  faqs: FAQ[];
  faqHeader = faqHeader;
  openingModal = false;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    public modalController: ModalController,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: PlatformId,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.meta.updateTag({ name: 'title', content: 'Laundry For Your Small Business' });
    this.meta.updateTag({
      name: 'description',
      content:
        'Get your business laundry done hassle-free. Professional, efficient, and affordable. Boost productivity today! Same day and Next day delivery available.',
    });
    this.meta.updateTag({ name: 'keywords', content: 'laundry provider, business account' });
    this.titleService.setTitle('Laundry For Your Small Business');

    this.heroImg = 'bkg-hero.webp';

    this.faqs = faqs.map((faq: FAQ) => {
      // Clone the faq object to avoid modifying the original data
      const newFaq: FAQ = { ...faq };

      // Map the nestedTabs array and sanitize the panel property
      newFaq.nestedTabs = faq.nestedTabs.map(nestedTab => {
        // Clone the nestedTab object
        const newNestedTab = { ...nestedTab };

        // Sanitize the panel property and store it in the 'panel' property
        newNestedTab.panel = this.sanitizer.bypassSecurityTrustHtml(nestedTab.panel as string);

        return newNestedTab;
      });

      return newFaq;
    });
  }

  async getStarted() {
    if (this.openingModal) {
      return;
    }
    this.openingModal = true;
    const modal = await this.modalController.create({
      component: BusinessSignupComponent,
      cssClass: 'business-signup-modal',
      mode: 'ios',
    });
    await modal.present();
    this.openingModal = false;
    const { data } = await modal.onDidDismiss();
    if (data?.contactSales) {
      await this.openSalesModal();
    }
  }

  async openSalesModal() {
    if (this.openingModal) {
      return;
    }
    this.openingModal = true;
    const modal = await this.modalController.create({
      component: ContactSalesComponent,
      componentProps: {
        previousModalId: (await this.modalController.getTop())?.id,
      },
      cssClass: 'contact-sales-modal',
      mode: 'ios',
    });
    await modal.present();
    this.openingModal = false;

    const { data } = await modal.onDidDismiss();
    if (data?.formSubmitted) {
      await this.openConfirmModal();
    } else if (data?.signUp) {
      openCustomerAppWithQueryParams('signup');
    }
  }

  async openConfirmModal() {
    if (this.openingModal) {
      return;
    }
    this.openingModal = true;
    const modal = await this.modalController.create({
      component: ConfirmComponent,
      componentProps: {
        previousModalId: (await this.modalController.getTop())?.id,
      },
      cssClass: 'confirm-modal',
      mode: 'ios',
    });
    await modal.present();
    this.openingModal = false;
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      const canonical = data['canonical'];

      if (canonical) {
        addCanonicalTag(canonical, this.document);
      } else {
        addCanonicalTag('', this.document);
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.heroImg = window.matchMedia('(min-width: 767px)').matches ? 'bkg-hero-wide.webp' : 'bkg-hero.webp';
    }
  }

  heroContent: Array<string> = ['Got laundry?', 'Allow us.'];
  heroClasses = 'bottom-left hero home';
  promoTwoContent: Array<string> = ['Your linens like', "you've never seen them"];
  promoTwoImg = 'colorful-towels.webp';
  promoTwoClasses = 'bottom-center your-clothes';

  promoThreeContent: Array<string> = ['Your laundry,', 'finished.'];
  promoThreeImg = 'bkg-promo-three.webp';
  promoThreeClasses = 'bottom-left your-laundry';

  byLine = 'Professionally washed, flawlessly dried, expertly folded, and returned to you the very next day.';
}
