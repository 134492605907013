import { Injectable } from '@angular/core';
import { createClient } from 'contentful';
import { from } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  TypeADANoticeFields,
  TypeOpenPositionFields,
  TypePostFields,
  TypePressReleasesFields,
  TypePrivacyPolicyFields,
  TypeTermsOfServiceFields,
  TypeTopicFields,
} from './content-types';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  client = createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.accessToken,
    environment: environment.contentful.environment,
  });
  getPolicyContent(query?: object) {
    return from(
      this.client.getEntries<TypePrivacyPolicyFields>(
        Object.assign(
          {
            content_type: 'privacyPolicy',
          },
          query
        )
      )
    );
  }
  getTermsOfServiceContent(query?: object) {
    return from(
      this.client.getEntries<TypeTermsOfServiceFields>(
        Object.assign(
          {
            content_type: 'termsOfService',
          },
          query
        )
      )
    );
  }
  getADANotice(query?: object) {
    return from(
      this.client.getEntries<TypeADANoticeFields>(
        Object.assign(
          {
            content_type: 'adaNotice',
          },
          query
        )
      )
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPostBySlug(postSlug: string, query?: object) {
    return from(
      this.client.getEntries<TypePostFields>(
        Object.assign(
          {
            content_type: 'blogPost',
            'fields.slug[match]': postSlug,
          },
          query
        )
      )
    );
  }
  getPostsByTopic(topicId: string, limit = 3, skip = 0, slug?: string | null) {
    const query: any = {
      content_type: 'blogPost',
      'fields.topics.sys.id[in]': topicId,
      limit,
      skip,
      order: '-sys.createdAt',
    };

    if (slug !== null && typeof slug !== 'undefined') {
      query['fields.slug[ne]'] = slug;
    }

    return from(this.client.getEntries<TypePostFields>(query));
  }
  getAllBlogPost(query?: object) {
    return from(
      this.client.getEntries<TypePostFields>(
        Object.assign(
          {
            content_type: 'blogPost',
            limit: 9,
            order: '-sys.createdAt',
          },
          query
        )
      )
    );
  }
  getAllTopics(query?: object) {
    return from(
      this.client.getEntries<TypeTopicFields>(
        Object.assign(
          {
            content_type: 'topics',
            limit: 6,
            order: '-sys.createdAt',
          },
          query
        )
      )
    );
  }
  getPressReleases(query?: object) {
    return from(
      this.client.getEntries<TypePressReleasesFields>(
        Object.assign(
          {
            content_type: 'pressRelease',
          },
          query
        )
      )
    );
  }
  getOpenPositions(query?: object) {
    return from(
      this.client.getEntries<TypeOpenPositionFields>(
        Object.assign(
          {
            content_type: 'openPositions',
          },
          query
        )
      )
    );
  }
}
