<div class="check-area-panel" [class.faq-check]="faq">
  <div [hidden]="success || failure || serverFailure" class="check-your-area">
    <h2 *ngIf="!faq">Nationwide personal laundry service in over 500 cities</h2>
    <form id="zip-code-form" [formGroup]="zipCodeFormGroup" (ngSubmit)="checkZipCode()">
      <ion-item lines="none">
        <div class="input-wrapper" role="alert">
          <ion-input
            #zipCodeInput
            [class.input-faq]="faq"
            [class.check-area-input]="!faq"
            id="zip-code-input"
            type="text"
            placeholder="Type your zip code"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            value="zipCode"
            ngDefaultControl
            formControlName="ZipCode"
            (input)="handleChange($event)"
            (ionFocus)="onFocus()"
            (ionBlur)="onBlur()"
            [attr.tabindex]="hideInput ? '-1' : undefined"
            [ngClass]="{
              'invalid-input':
                zipCodeFormGroup.get('ZipCode')?.touched && zipCodeFormGroup.get('ZipCode')?.hasError('pattern')
            }"></ion-input>
          <span class="vertical-line" [ngClass]="{ hidden: inputFocused || hideCursor }"></span>
        </div>
      </ion-item>
      <ion-item lines="none" role="status">
        <div *ngIf="loading" id="check-zip-button" class="checking-area" role="status">
          <ion-spinner name="crescent"></ion-spinner>
          Checking service in your zip code
        </div>
      </ion-item>
      <ion-item lines="none" *ngIf="invalidZipForm && zipCodeFormGroup.get('ZipCode')?.hasError('required')">
        <div class="zip-code-form-error" role="alert">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 15.0898C11.6598 15.0898 11.7938 15.0357 11.9019 14.9276C12.01 14.8195 12.0641 14.6855 12.0641 14.5256C12.0641 14.3658 12.01 14.2318 11.9019 14.1237C11.7938 14.0156 11.6598 13.9615 11.5 13.9615C11.3402 13.9615 11.2062 14.0156 11.0981 14.1237C10.99 14.2318 10.9359 14.3658 10.9359 14.5256C10.9359 14.6855 10.99 14.8195 11.0981 14.9276C11.2062 15.0357 11.3402 15.0898 11.5 15.0898ZM11.0417 12.0577H11.9583V6.5577H11.0417V12.0577ZM11.5031 19.25C10.3622 19.25 9.28966 19.0335 8.28534 18.6006C7.28103 18.1676 6.4074 17.58 5.66448 16.8378C4.92156 16.0955 4.33342 15.2227 3.90005 14.2194C3.46668 13.216 3.25 12.1439 3.25 11.0031C3.25 9.86223 3.46648 8.78966 3.89944 7.78534C4.33241 6.78103 4.92001 5.90741 5.66223 5.16448C6.40446 4.42156 7.27725 3.83342 8.28062 3.40005C9.284 2.96668 10.3561 2.75 11.4969 2.75C12.6378 2.75 13.7103 2.96648 14.7147 3.39944C15.719 3.83241 16.5926 4.42001 17.3355 5.16223C18.0784 5.90446 18.6666 6.77725 19.0999 7.78062C19.5333 8.784 19.75 9.85611 19.75 10.9969C19.75 12.1378 19.5335 13.2103 19.1006 14.2147C18.6676 15.219 18.08 16.0926 17.3378 16.8355C16.5955 17.5784 15.7227 18.1666 14.7194 18.5999C13.716 19.0333 12.6439 19.25 11.5031 19.25Z"
              fill="#F5003D" />
          </svg>
          Sorry, input can't be empty
        </div>
      </ion-item>
      <ion-item
        lines="none"
        *ngIf="zipCodeFormGroup.get('ZipCode')?.touched && zipCodeFormGroup.get('ZipCode')?.hasError('pattern')"
        cocaCola="1">
        <div class="zip-code-form-error" role="alert">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5 15.0898C11.6598 15.0898 11.7938 15.0357 11.9019 14.9276C12.01 14.8195 12.0641 14.6855 12.0641 14.5256C12.0641 14.3658 12.01 14.2318 11.9019 14.1237C11.7938 14.0156 11.6598 13.9615 11.5 13.9615C11.3402 13.9615 11.2062 14.0156 11.0981 14.1237C10.99 14.2318 10.9359 14.3658 10.9359 14.5256C10.9359 14.6855 10.99 14.8195 11.0981 14.9276C11.2062 15.0357 11.3402 15.0898 11.5 15.0898ZM11.0417 12.0577H11.9583V6.5577H11.0417V12.0577ZM11.5031 19.25C10.3622 19.25 9.28966 19.0335 8.28534 18.6006C7.28103 18.1676 6.4074 17.58 5.66448 16.8378C4.92156 16.0955 4.33342 15.2227 3.90005 14.2194C3.46668 13.216 3.25 12.1439 3.25 11.0031C3.25 9.86223 3.46648 8.78966 3.89944 7.78534C4.33241 6.78103 4.92001 5.90741 5.66223 5.16448C6.40446 4.42156 7.27725 3.83342 8.28062 3.40005C9.284 2.96668 10.3561 2.75 11.4969 2.75C12.6378 2.75 13.7103 2.96648 14.7147 3.39944C15.719 3.83241 16.5926 4.42001 17.3355 5.16223C18.0784 5.90446 18.6666 6.77725 19.0999 7.78062C19.5333 8.784 19.75 9.85611 19.75 10.9969C19.75 12.1378 19.5335 13.2103 19.1006 14.2147C18.6676 15.219 18.08 16.0926 17.3378 16.8355C16.5955 17.5784 15.7227 18.1666 14.7194 18.5999C13.716 19.0333 12.6439 19.25 11.5031 19.25Z"
              fill="#F5003D" />
          </svg>
          Please input a valid 5-digit zip code
        </div>
      </ion-item>
      <button
        *ngIf="!faq && !bottomHome && !loading"
        id="check-zip-button"
        class="check-your-area-button large"
        type="submit"
        (click)="checkZipCode()">
        <span>Check your area</span>
      </button>
      <button
        *ngIf="!faq && bottomHome && !loading"
        id="check-zip-button-2"
        class="check-your-area-button large"
        type="submit"
        (click)="checkZipCode()">
        <span>Check your area</span>
      </button>
      <button
        *ngIf="faq!; loading"
        id="check-zip-button-3"
        class="check-your-area-button small"
        type="submit"
        (click)="checkZipCode()"
        [class.disabled]="loading || emptyZipCode"
        [disabled]="loading || emptyZipCode">
        <span>Check your area</span>
      </button>
    </form>
  </div>

  <div [hidden]="!success || failure || loading" class="check-your-area success">
    <h2 role="status">Perfect, we're in your area!</h2>
    <form id="zip-code-form" [formGroup]="zipCodeFormGroup">
      <ion-item lines="none">
        <div class="input-wrapper" role="alert">
          <ion-input
            [class.input-faq]="faq"
            [class.check-area-input]="!faq"
            class="valid-input"
            id="zip-code-input"
            type="text"
            placeholder="Type your zip code"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            value="zipCode"
            ngDefaultControl
            formControlName="ZipCode"
            (input)="handleChange($event)"
            (ionFocus)="onFocus()"
            (ionBlur)="onBlur()"
            aria-hidden="true"
            aria-disabled="true"
            tabindex="-1"
            *ngIf="!loading"></ion-input>
          <span class="vertical-line" [ngClass]="{ hidden: inputFocused || hideCursor }"></span>
        </div>
      </ion-item>
    </form>
    <button
      *ngIf="!faq && !business && !bottomHome"
      id="zip-code-log-in-button"
      class="create-account-button large create"
      (click)="signUp($event)"
      role="button"
      type="button"
      tabindex="0">
      <span>Create Account</span>
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg" role="presentation"></ion-icon>
      </div>
    </button>

    <button
      *ngIf="!faq && !business && bottomHome"
      id="zip-code-log-in-button-2"
      class="create-account-button large create"
      (click)="signUp($event)"
      role="button"
      type="button"
      tabindex="0">
      <span>Create Account</span>
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg" role="presentation"></ion-icon>
      </div>
    </button>
    <div class="zip-code-check-button-container">
      <button
        id="pro-wash-get-started-business-button"
        *ngIf="business"
        class="create-account-button large started"
        (click)="getStartedBusiness()"
        tabindex="0">
        <span>Get Started</span>
        <div class="btn-icon" aria-hidden="true">
          <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </button>
      <button
        [class.be-a-laundry-pro]="faq"
        *ngIf="faq && !business"
        id="zip-code-lp-button"
        class="create-account-button large lp"
        (click)="signUpPro()"
        tabindex="0">
        <span> Be A Laundry Pro</span>
        <div class="btn-icon" aria-hidden="true">
          <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </button>

      <button id="zip-code-reset-button" class="check-zip-code-button" (click)="resetCheck()">
        <span>Check another Zip Code</span>
      </button>
    </div>
  </div>

  <div [hidden]="success || !failure" [class.failure-faq]="faq" class="check-your-area failure">
    <!-- <h2>{{ zipCode }}</h2> -->
    <h2 role="status">Sorry, we're not in your area yet.</h2>
    <form id="zip-code-form" [formGroup]="zipCodeFormGroup">
      <ion-item lines="none">
        <div class="input-wrapper" role="alert">
          <ion-input
            [class.input-faq]="faq"
            [class.check-area-input]="!faq"
            class="invalid-input"
            id="zip-code-input"
            type="text"
            placeholder="Type your zip code"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            value="zipCode"
            ngDefaultControl
            formControlName="ZipCode"
            (input)="handleChange($event)"
            (ionFocus)="onFocus()"
            (ionBlur)="onBlur()"
            aria-hidden="true"
            aria-disabled="true"
            tabindex="-1"
            *ngIf="!loading"></ion-input>
          <span class="vertical-line" [ngClass]="{ hidden: inputFocused || hideCursor }"></span>
        </div>
      </ion-item>
    </form>
    <button
      *ngIf="!bottomHome"
      id="zip-code-failure-reset-button"
      class="check-zip-code-button"
      (click)="resetCheck()"
      tabindex="0">
      <span>Check another Zip Code</span>
    </button>
    <button
      *ngIf="bottomHome"
      id="zip-code-failure-reset-button-2"
      class="check-zip-code-button"
      (click)="resetCheck()"
      tabindex="0">
      <span>Check another Zip Code</span>
    </button>
  </div>
  <div [hidden]="success || failure || !serverFailure" [class.failure-faq]="faq" class="check-your-area traffic">
    <p>
      We're experiencing a high volume of traffic right now and our system is unable process your request. Please try
      again in a few moments.
    </p>
    <button id="zip-code-failure-reset-button" class="medium filled" (click)="resetCheck()">Try Again</button>
  </div>
</div>
