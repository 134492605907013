import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PlatformId } from 'src/app/types/common';
import { addCanonicalTag } from 'src/app/util/canonical-tag';

@Component({
  standalone: true,
  selector: 'app-gift-cards',
  templateUrl: './gift-cards.component.html',
  styleUrls: ['./gift-cards.component.scss'],
})
export class GiftCardsComponent implements OnInit {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: PlatformId,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.meta.updateTag({ name: 'title', content: 'Gift Cards - Pickup Laundry Service' });
    this.meta.updateTag({
      name: 'description',
      content:
        'The perfect gift: No more laundry! Poplin sells digital gift cards starting at $25 for our on-demand pickup/delivery laundry service.',
    });
    this.meta.updateTag({ name: 'keywords', content: 'gift card, pickup laundry service, poplin' });
    this.titleService.setTitle('Gift Cards - Pickup Laundry Service');
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      const canonical = data['canonical'];

      if (canonical) {
        addCanonicalTag(canonical, this.document);
      } else {
        addCanonicalTag('', this.document);
      }
    });

    if (this.platformId === 'browser') {
      this.injectGiftUpScript();
    }
  }

  private setGiftUpIframeTitle() {
    const checkIframe = () => {
      const iframe = document.querySelector('iframe[src*="https://cdn.giftup.app"]');

      if (iframe) {
        iframe.setAttribute('title', 'Gift Up - buy gift cards');
      } else {
        setTimeout(checkIframe, 100);
      }
    };
    checkIframe();
  }

  private injectGiftUpScript() {
    const giftUpScript = document.createElement('script');
    giftUpScript.type = 'text/javascript';
    giftUpScript.async = true;
    giftUpScript.src = '/assets/scripts/gift-up.js';
    giftUpScript.onload = () => this.setGiftUpIframeTitle();
    document.head.appendChild(giftUpScript);
  }
}
